










































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

// components
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import Tag from '@/components/_uikit/Tag.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
// store
import { CourseType, UserBoughtGroupResource } from '@/store/types'
import { countDayInMonth, formatDate } from '@/utils/functions'

@Component({
  components: {
    ButtonArrow,
    Tag,
    UserSocialCard,
  },
})
export default class BoughtCoursesListCard extends Vue {
  @Prop({ required: true })
  private course!: UserBoughtGroupResource

  private get isSpecial () {
    return this.course.course.type.value === CourseType.SPECIAL
  }

  private get lastMonth() {
    return this.course.months[this.course.months.length - 1].name
  }

  private get countDay() {
    return countDayInMonth(this.lastMonth)
  }

  private get month() {
    return formatDate(this.lastMonth, 'MMM yyyy')
  }
}
