







import { Component, Vue } from 'vue-property-decorator'

import TelegramIcon from '@/components/TelegramIcon.vue'

@Component({
  components: {
    TelegramIcon,
  },
})
export default class TelegramButton extends Vue {}
