






















import { Component, Prop, Mixins } from 'vue-property-decorator'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import VKIDButton from '@/components/VKIDButton.vue'
import TelegramButton from '@/components/TelegramButton.vue'
// types
import { SocialResource } from '@/store/types'
// mixins
import VKIDMixin from '@/mixins/VKIDMixin'
import TelegramMixin from '@/mixins/TelegramMixin'

@Component({
  components: {
    Dialog,
    ModalWrapper,
    VKIDButton,
    TelegramButton,
  },
})
export default class AttachSocialsModal extends Mixins(VKIDMixin, TelegramMixin) {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ default: () => ([]) })
  private socials!: SocialResource[]

  private get normalizeSocials() {
    return this.socials.map(item => item.type) as string[]
  }

  private get modalTitle() {
    let base = 'Для использования сервиса необходимо привязать свой аккаунт'
    let count = 0

    if(!this.normalizeSocials.includes('vkontakte')) {
      count++
      base += count ? ' VK' : ' и VK'
    }

    if(!this.normalizeSocials.includes('telegram')) {
      count++
      base += count <= 1 ? ' Telegram' : ' и Telegram'
    }

    return base
  }

  private mounted() {
    this.$bus.$on('closeAttachSocialsModal', this.handleClose)
  }

  private destroyed() {
    this.$bus.$off('closeAttachSocialsModal', this.handleClose as any)
  }

  private handleClose() {
    this.$emit('update:visible', false)
  }
}
