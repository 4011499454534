
















import { MetaInfo } from 'vue-meta'
import { Component } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import AttachSocialsModal from '@/components/modals/AttachSocialsModal.vue'
import BoughtCoursesListCard from '@/components/cards/BoughtCoursesListCard.vue'
import Tag from '@/components/_uikit/Tag.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
// store
import ProfileModule from '@/store/modules/profile'
import { UserBoughtGroupResource, UserLargeResource, SocialResource } from '@/store/types'

@Component({
  components: {
    AttachSocialsModal,
    BoughtCoursesListCard,
    Tag,
    UserSocialCard,
  },
})
export default class Courses extends NotifyMixin {
  private profileCourses: UserBoughtGroupResource[] = []
  private socials: SocialResource[] = []

  private get information () {
    return ProfileModule.information
  }

  private showAttachSocialsModal = false

  private fetchProfileCourses() {
    ProfileModule.fetchProfileCourses()
      .then(response => this.profileCourses = response)
      .catch(this.notifyError)
  }

  private mounted() {
    if (!this.profileCourses.length) {
      this.fetchProfileCourses()
    }
    if (!this.information) {
      ProfileModule.fetchInformation()
        .then((response: UserLargeResource) => {
          this.socials = response.socials
          this.showAttachSocialsModal = !response.hasSocials
        })
        .catch(this.notifyError)
    } else {
      this.socials = this.information.socials
      this.showAttachSocialsModal = !this.information.hasSocials
    }
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Курсы',
    }
  }
}
