import { render, staticRenderFns } from "./BoughtCoursesListCard.vue?vue&type=template&id=5b8d9a87&scoped=true&lang=pug&"
import script from "./BoughtCoursesListCard.vue?vue&type=script&lang=ts&"
export * from "./BoughtCoursesListCard.vue?vue&type=script&lang=ts&"
import style0 from "./BoughtCoursesListCard.vue?vue&type=style&index=0&id=5b8d9a87&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b8d9a87",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
